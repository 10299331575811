import Image from 'next/image'
import { useEffect } from 'react'
import { Layout } from '../components/Layout'
import { AuthorPosts } from '../components/Author/AuthorPosts'
import { BRAND, STRAPI_IMGX } from '../constants'
import { PrimaryCTA } from '../components/UI'
import { Meta } from '../components/Landing'
import { externalImageLoader } from '../utilities/externalImageLoader'

const renderImage = (photo) => {
  return `${STRAPI_IMGX}/${photo}?fit=facearea&facepad=10&w=800&h=800`
}

export const AuthorPage = ({
  megaMenu,
  footer,
  authorId,
  firstName,
  lastName,
  jobTitle,
  bio,
  photo,
  locale,
  authorLocale,
  listicles,
  schema,
  pagination,
  totalListicleCount,
  page,
  baseUrl,
  primaryCta,
  callToActionURL
}) => {
  const urlPage = page > 1 ? `?page=${page}` : ''

  useEffect(() => {
    if (locale !== authorLocale) {
      let localePrefix = ''
      if (authorLocale !== 'GB') {
        localePrefix = authorLocale.toLowerCase() + '/'
      }
      window.history.pushState(
        null,
        null,
        `${baseUrl}/${localePrefix}author/${authorId}-${firstName}-${lastName}${urlPage}`
      )
    } else {
      window.history.pushState(
        { urlPath: '/author' },
        '',
        `${authorId + '-' + firstName + '-' + lastName + urlPage}`
      )
    }
  }, [authorId])

  return (
    <Layout {...{ navType: 'STANDARD', megaMenu, footer }}>
      <Meta
        metaTitle={`${firstName + ' ' + lastName + ' - ' + jobTitle + ' | HeadBox'}`}
        metaDescription={`A Page of our authors.`}
        schema={schema}
      />
      <div className='author-page-container'>
        <div className='author-info'>
          <div className='author-image'>
            <Image
              src={renderImage(photo)}
              loader={externalImageLoader}
              alt={`${firstName} ${lastName}`}
              layout='fill'
              objectFit='cover'
            />
          </div>
          <div className='author-details'>
            <text className='author-name'>
              {firstName} {lastName}
            </text>
            <p className='author-job-title'>{jobTitle}</p>
            <p className='author-bio'>{bio}</p>
          </div>
        </div>

        <AuthorPosts
          baseUrl={baseUrl}
          listicles={listicles}
          firstName={firstName}
          authorId={authorId}
          authorLocale={authorLocale}
          pagination={pagination}
          totalListicleCount={totalListicleCount}
        />

        <PrimaryCTA {...{ primaryCta, callToActionURL }} />
      </div>
      <style jsx>{`
        .author-page-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 40px;
        }

        .author-info {
          display: grid;
          grid-template-columns: 40% 1fr;
          border: 1px solid #d9d9d9;
          border-radius: 8px;
          overflow: hidden;
          margin-bottom: 48px;
          align-content: center;
          max-width: 70%;
          min-width: 70%;
        }

        .author-image {
          width: 100%;
          object-fit: cover;
          overflow: hidden;
          height: 370px;
          position: relative;
        }

        .author-details {
          display: flex;
          flex-direction: column;
          padding: 24px 48px;
          justify-content: center;
        }

        .author-name {
          margin: 0;
          font-size: 32px;
          font-weight: bold;
          color: black;
          font-family: Montserrat;
        }

        .author-job-title {
          font-size: 18px;
          color: black;
          font-weight: bold;
          font-family: Montserrat;
        }

        .author-bio {
          margin: 0;
          font-size: 16px;
          color: #555;
        }

        @media screen and (max-width: ${BRAND.media.small}px) {
          .author-info {
            display: grid;
            grid-template-columns: 1fr;
            border: 1px solid #d9d9d9;
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: 48px;
            align-content: center;
            max-width: 90%;
            min-width: 90%;
          }
        }
      `}</style>
    </Layout>
  )
}
